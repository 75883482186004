import React from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import moment from 'moment';
import { getHtml } from 'utils/getHtmlFromDraft';

function DescriptionModal({ show, report, handleClose }) {

    if (show===false || report === undefined || report.description === undefined) {
        return (
            <></>
        )
    } else {
        const publishDateStr = `${moment(report.publishDate).format('dddd')}, ${moment(report.publishDate).format('MMM DD, YYYY')}`;
        return (
            <Modal show={show} onHide={handleClose} size='lg' >
                <Modal.Body>
                    <Card>
                        <Card.Header>
                            {report.reportType} - {publishDateStr}
                        </Card.Header>
                        <Card.Body>
                            {getHtml(report.description)}
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }



}

export default DescriptionModal;