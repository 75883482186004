import React, { useState, useEffect } from 'react';
import { Row} from 'react-bootstrap';
import ApiLoader from 'components/commons/ApiLoader';
import SelectField from 'components/commons/SelectField';
import { getRequest } from 'utils/RequestUtils';

function CommCountryYearMonthDropdown( { setCommodity, setCountry, setMonth, setYear } ){

    const [apiResponse, setApiResponse] = useState({commodities: [], country: [], months: [], years: [],});
    const [isLoading, setLoading] = useState(true);

    const fetchAgmArrivalMap = () => {
        const path = '/api/annualExIm/getAnnualExImMap';
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchMap = async () => {
            await fetchAgmArrivalMap();
            setLoading(false);
        };
        fetchMap();
    }, []);

    if (isLoading === false){
        if (apiResponse.commodities.length > 0) {
            return (
                <Row>
                        <SelectField
                            label='Select Commodity'
                            arr={apiResponse.commodities || []}
                            setSelection={setCommodity}
                            defaultValue='' />
                        <SelectField
                            label='Select Country'
                            arr={apiResponse.country || []}
                            setSelection={setCountry}
                            defaultValue='' 
                        />
                         <SelectField
                            label='Select Year'
                            arr={apiResponse.years || []}
                            setSelection={setYear}
                            defaultValue='' 
                        />
                         <SelectField
                            label='Select Month'
                            arr={apiResponse.months || []}
                            setSelection={setMonth}
                            defaultValue='' 
                        />
                </Row>
            );
        } else {
            return (
                <div>No Records Found</div>
            );
        }
    } else {
        return (
            <ApiLoader />
        );
    }

}

export default CommCountryYearMonthDropdown;