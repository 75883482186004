import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from 'router/AuthContext';
import { postRequest } from 'utils/RequestUtils';

function LogoutButton(props) {
    const { logout } = useContext(AuthContext);
    const token = localStorage.getItem('token');

    const handleLogout = (event) => {
        event.preventDefault();
        const path = '/api/logout';
        const body = {token};
        const successFunc = (data) => {
            logout();
        }
        const errorFunc = (res) => {
            logout();
        };
        postRequest(path, body, successFunc, errorFunc);
    };

    return (
        <Button className='LogoutBtn' onClick={e => handleLogout(e)}>
          &nbsp;&nbsp;&nbsp;Logout
        </Button>
        
    );
}

export default LogoutButton;