import React from 'react';
import { Form, Row} from 'react-bootstrap';

function DateRangeFilter({ setStartDate, setEndDate, startDateLabel='Start Date', endDateLabel='End Date' }){

    return (
        <>
            <Row>
                <Form.Group controlId="FormStartDate">
                    <Form.Label >{startDateLabel}</Form.Label>
                    <Form.Control type='date' placeholder='Enter Date' onChange={(e) => setStartDate(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group controlId="FormEndDate">
                    <Form.Label >{endDateLabel}</Form.Label>
                    <Form.Control type='date' placeholder='Enter Date' onChange={(e) => setEndDate(e.target.value)} />
                </Form.Group>
            </Row>
        </>
    );
}

export default DateRangeFilter;