import React from 'react';
import { Card, Button, Row } from 'react-bootstrap';
import { openFile } from 'utils/awsFileUtils';
import moment from 'moment';
import './mobileReportCard.css';

function MobileLatestReportCard ({ reportData }){
    // console.log(`report Data : ${JSON.stringify(reportData)}`);

    const publishDateStr = `${moment(reportData.publishDate).format('dddd')}, ${moment(reportData.publishDate).format('MMM DD, YYYY')}`;
    return (
        
        
        <Card className='MobileReportCard' id='MobileReportCard' style={{ marginBottom: '5px', marginTop: '0vh', border: 'solid black 0.1rem' , width:'100%' }}>
            <Card.Body style={{ padingLeft: '1rem', width: '100%' }}>
                <Row>
                    <div className='mobRepCard_Col_1'>
                        <Row className='ReportType'>{reportData.reportType}</Row>
                        <Row>{publishDateStr}</Row>
                    </div>
                    <div className='mobRepCard_Col_2'>
                        <Button
                            variant='success'
                            onClick={() => openFile(reportData._id)}
                        >
                            <center>&darr;</center>
                        </Button>
                    </div>
                </Row>
            </Card.Body>
        </Card>
       

    )

}

export default MobileLatestReportCard;