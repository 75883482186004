import { red } from '@mui/material/colors';
import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

const styles = {
    Card:{
        color:red,
    }
}

function PersonalInfoSection({ clientData, setShowModal }){

    const showModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    }

    return (
        <Card className='card1'>
            <Card.Header style={styles.table}>
                Personal Info
            </Card.Header>
            <Card.Body className='cardbody1'>
                <Row>
                    <Col> Name : </Col>
                    <Col> {clientData.name}</Col>
                </Row>
                <Row>
                    <Col> Email : </Col>
                    <Col> {clientData.email} </Col>
                </Row>
                <Row>
                    <Col> Contact Number: </Col>
                    <Col> {clientData.phone} </Col>
                </Row>
                <Row>
                    <Col> Organization : </Col>
                    <Col> {clientData.organization} </Col>
                </Row>
                <Row>
                    <Col> Account Status : </Col>
                    <Col> {clientData.status} </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                {/* <Button variant='primary' className='submitBtn' onClick={(e) => showModal(e)} >
                    Edit Profile
                </Button> */}
                <a href="mailto:ram@agalabs.com?subject=Please Edit My Profile">
                    <Button variant='primary' className='submitBtn'>
                        Contact Admin
                    </Button>
                </a>
                
            </Card.Footer>
        </Card>
    )

}

export default PersonalInfoSection;