import React, { useState } from 'react';
import { Container, Form, Row , Button} from 'react-bootstrap';
import CommodityLocationCombo from 'components/selectors/CommodityLocationCombo';
import DateRangeFilter from 'components/dataService/DateRangeFilter';
import SubmitClearBtn from 'components/commons/SubmitClearBtn';
import PageManager from 'components/commons/PageManager';
import './pipelineComponents.css'

function CommLocationDateRangeFilter({ setFilter, submit, clear, maxPages, requestPath }) {

    const [commodity, setCommodity] = useState('');
    const [location, setLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);
    function showDiv() {
        var btn = document.getElementById('filterbtn');
        // var Mobcard = document.getElementById('MobileReportCard');
        btn.style.width = '100vw';
        var filtrBtn = document.getElementById('filtrBtn');
        filtrBtn.innerHTML = 'Close';
        if (btn.style.display === "none") {
            // Mobcard.style.marginTop= '2rem';
            btn.style.marginBottom = '0rem!important';
            btn.style.width="70vw"
            filtrBtn.innerHTML = 'Close';
            btn.style.display = "block";
          } else {
            btn.style.display = "none";
            filtrBtn.innerHTML = 'Filters';
            // btn.style.marginTop= '-9vh' ;
            btn.style.marginBottom = '0rem!important';
          }
     }
    const submitFilter = () => {
        const filter = {
            commodity, location, startDate, endDate, pageNumber
        };
        setFilter(filter);
        submit();
    };

    return (
       <><Button id='filtrBtn'  onClick={() => showDiv()}>Filters</Button>
        <Container id='filterContainer'>
            
            <Form id='filterbtn'>
                <CommodityLocationCombo path={requestPath} selectCommodity={setCommodity} selectLocation={setLocation} vertical={true} />
                <DateRangeFilter setStartDate={setStartDate} setEndDate={setEndDate} />
                <Row style={{marginTop:'20px',marginBottom:'20px'}}>
                    <PageManager managePage={setPageNumber} maxPages={maxPages} />
                </Row>
                <SubmitClearBtn submit={submitFilter} clear={clear} />
            </Form>
        </Container>
        </> 
    );
}

export default CommLocationDateRangeFilter;