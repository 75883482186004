import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import PersonalInfoSection from './profile/PersonalInfoSection';
import SubscriptionSection from './profile/SubscriptionSection';

function ClientProfile() {
    const [isLoading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState({});
    const [, setShow] = useState(false);

    // const closeModal = () => {
    //     setShow(false);
    // }

    const fetchClientData = async () => {
        const path = '/api/clientUser/fetch';
        const successFunc = (data) => {
            setResponseData(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchClientData();
            setLoading(false);
        };
        fetchData();
    }, []);

    if(isLoading === false){
        if (Object.keys(responseData).length > 0){
            return (
                <Container>
                    <Row>
                        <Col><PersonalInfoSection clientData={responseData} setShowModal={setShow} /></Col>
                        <Col><SubscriptionSection clientData={responseData} /></Col>
                    </Row>
                    {/* <EditProfileModal show={show} modalData={responseData} handleClose={closeModal} /> */}
                </Container>
            )
        } else {
            return <div>Data Not Found, Contact Admin</div>
        }
    } else {
        return <ApiLoader />;
    }
}

export default ClientProfile;