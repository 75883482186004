import React from 'react';
import {Card,Row,Col} from 'react-bootstrap';
import moment from 'moment';
import './data_service.css';

function Mobile_Card ({ dataList, headerList}){
    const cardComplete = (
        <div>                 
                        {
                                dataList.map((data, index) => {
                                    return (
                                        <Card id="Mob_Card">
                                            <Card.Body style={{ padingLeft: '0rem', width: '100%' }}>
                                                <div className='mobRepCard_Col_'>
                                                    <Row>
                                                        {headerList.map((header, index) => {
                                                            if ((header.includes('date') || header.includes('publishDate'))) {
                                                                return (
                                                                    <Row>
                                                                        {moment(data[header]).format('DD MMM YYYY	')}
                                                                    </Row>
                                                                );
                                                            }
                                                            else if (header.includes('price') || header.includes('value')) {
                                                                return (
                                                                        <Col className="Price">{data[header]}</Col>
                                                                    );
                                                            }
                                                            else if (header.includes('location')) {
                                                                return (
                                                                    <Col className="Location">
                                                                        {data[header]}
                                                                    </Col>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Row className="spotPrice-table__cell">
                                                                        {data[header]}
                                                                    </Row>
                                                                );
                                                            }
                                                        })}
                                                    </Row>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })
                            }       </div>
    );
    return cardComplete;
}

export default Mobile_Card;