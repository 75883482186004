import React from 'react';
import { Table, Container } from 'react-bootstrap';
import moment from 'moment';
import './pipelineComponents.css';

function DataPipelineTable({ dataList, headerList, maxPages=1, managePage=()=>{}}) {

    const a = (
        <>
            <Container className='desktopView' >
                <div className='dataTableContainer'>
                    <Table size="sm">
                        <thead>
                            <tr className='dataTableHeader'>
                                {
                                    headerList.map((header, index) => {
                                        return (<th key={index}>{header}</th>);
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                headerList.map((header, index) => {
                                                    if (header.includes('date') || header.includes('publishDate')) {
                                                        return (
                                                            <td className="spotPrice-table__cell" key={index}>
                                                                {moment(data[header]).format('DD-MM-YYYY')}
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td className="spotPrice-table__cell" key={index}>
                                                                {data[header]}
                                                            </td>
                                                        );
                                                    }

                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Container >
        </>
    );
    return a;
}

export default DataPipelineTable;