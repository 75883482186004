import React from 'react';
import RouteManager from './RouteManager';
import { Container, Row } from 'react-bootstrap';
import Header from 'dashboard/dashboard_sections/header/Header';
import Footer from 'dashboard/dashboard_sections/footer/Footer';
import './dashboard.css';

function Dashboard() {
    return (
        <Container fluid className='main-container'>
            <Row className='dashboard-main-header'>
                <Header />
            </Row>
            <Row className='dashboard-main-content'>
                <RouteManager />
            </Row>
            <Row className='dashboard-main-footer'>
                <Footer />
            </Row>
        </Container>
    );
}

export default Dashboard;
