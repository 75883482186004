import React from 'react';
import { Form, Row } from 'react-bootstrap';

function DateRangeFilter({ setStartDate, setEndDate }){

    return (
        <Row style={{width: '100%'}}>
            <Form.Group controlId="FormStartDate" style={{width: '100%'}}>
                <Form.Label >Start Date</Form.Label>
                <Form.Control type='date' placeholder='Enter Date' onChange={(e) => setStartDate(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="FormEndDate" style={{width: '100%'}}>
                <Form.Label >End Date</Form.Label>
                <Form.Control type='date' placeholder='Enter Date' onChange={(e) => setEndDate(e.target.value)} />
            </Form.Group>
        </Row>
    );
}

export default DateRangeFilter;