import React from 'react';
import Pagination from '@mui/material/Pagination';


function PageManager({ maxPages, managePage }) {

    const handleChange = (event, value) => {
        managePage(value);
    };

    return (
        <Pagination count={maxPages} shape="rounded" onChange={handleChange} />
    );
}

export default PageManager;