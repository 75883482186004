import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import TableSection from 'components/dataService/TableSection';
// import CommLocationDateRangeFilter from 'components/dataService/CommLocationDateRangeFilter';
import AnnualExImFilter from './AnnualExImFilter';

function AnnualExIm() {

    const [filter, setFilter] = useState({
        commodity: '',
        country: '',
        year: '',
        hsCode: '',
        pageNumber: 1,
    });
    
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const fetchData = async () => {
        const path = `/api/annualExIm/fetch?commodity=${filter.commodity}&year=${filter.year}&hsCode=${filter.hsCode}&pageNumber=${filter.pageNumber}&country=${filter.country}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

    if(isLoading===false){
        if (apiResponse.records.length >= 0){
            return (
                <Container fluid>
                    <h2>Annual Export / Import</h2>
                    <Row style={{marginTop:'30px'}}>
                        <Col>
                            <AnnualExImFilter 
                               setFilter={setFilter}
                               maxPages={apiResponse.maxPageNumber}
                               submit={submitFilter}
                               clear={refreshPage}
                            />
                        </Col>
                        <Col xs={9}>
                            <TableSection
                                dataList={apiResponse.records}
                                headerList={['year', 'country', 'commodity', 'value', 'hsCode']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <div>
                    No Records Found
                </div>
            );
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default AnnualExIm;