import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ArchivePage from "./agri_reports/archives/ArchivePage";
import SingleReportPage from './agri_reports/singleReport/SingleReportPage';
import LatestReportsPage from './agri_reports/latestReports/LatestReportsPage';
import EditUser from "./user_control/ClientProfile";
import ArchiveNewsPage from "./marketUpdate/archive/ArchiveNewsPage";
import SpotPrices from "./data_service/spotPrice/SpotPrices";
import FcaRetail from "./data_service/fcaRetail/FcaRetail";
import FcaWholesale from "./data_service/fcaWholesale/FcaWholesale";
import NcdexSpot from "./data_service/ncdexSpot/NcdexSpot";
import NcdexStock from "./data_service/ncdexStock/NcdexStock";
import AnnualExIm from "./data_service/annualExIm/AnnualExIm";
import MonthlyExIm from "./data_service/monthlyExIm/MonthlyExIm";
import Forex from "./data_service/forex/Forex";
import AgmArrival from "./data_service/agmakrnetArrival/AgmArrival"

function RouteManager() {

    return (
        <Router>
            <Switch>
                <Route  path ="/dashboard/user/profile" component={EditUser} />
                <Route exact path='/dashboard/' component={LatestReportsPage} />
                <Route path="/dashboard/reports/archive" component={ArchivePage} />
                <Route path ="/dashboard/reports/single" component={SingleReportPage} />
                <Route path ="/dashboard/reports/latest" component={LatestReportsPage} />   
                <Route path="/dashboard/marketUpdate/archive" component={ArchiveNewsPage} />
                <Route path="/dashboard/priceInfo/spotPrice" component={SpotPrices} />
                <Route path ="/dashboard/priceInfo/fcaRetail" component={FcaRetail} />
                <Route path ="/dashboard/priceInfo/fcaWholesale" component={FcaWholesale} />
                <Route path ="/dashboard/priceInfo/ncdexSpot" component={NcdexSpot} />
                <Route path ="/dashboard/priceInfo/ncdexStock" component={NcdexStock} />
                <Route path ="/dashboard/priceInfo/forex" component={Forex} />
                <Route path ="/dashboard/tradeDetails/agmarknetArrival" component={AgmArrival} />
                <Route path ="/dashboard/tradeDetails/annualExIm" component={AnnualExIm} />
                <Route path ="/dashboard/tradeDetails/monthlyExIm" component={MonthlyExIm} />
                
            </Switch>
        </Router>
    )
}

export default RouteManager;