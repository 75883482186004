import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import './archiveReport.css';
import moment from 'moment';
import DescriptionModal from './DescriptionModal';

function MarketUpdateTable({ marketList }) {

    const defaultVal = {

    };
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(defaultVal);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (event, index) => {
        event.preventDefault();
        const marketData = marketList[index];
        setModalData(marketData);
        setShow(true);
    };

    return (
        <>
                <Table size="sm">
                    <thead>
                        <tr className="report-table__header">
                            {/* <th className="report-table__cell cell_width_commodity">Commodity</th> */}
                            <th className="report-table__cell cell_width_name">Heading</th>
                            <th className="report-table__cell cell_width_type">Report Type</th>
                            <th className="report-table__cell cell_width_date">Publish Date</th>
                            <th className="report-table__cell cell_width_desc">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            marketList.map((marketData, index) => {
                                const publishDateStr = `${moment(marketData.publishDate).format('dddd')}, ${moment(marketData.publishDate).format('MMM DD, YYYY')}`;
                                return (
                                    <tr key={index}>
                                        <td className="report-table__cell cell_width_type text-capitalize">{marketData.heading}</td>
                                        {/* <td className="report-table__cell cell_width_commodity text-capitalize">{marketData.commodity}</td> */}
                                        <td className="report-table__cell cell_width_type text-capitalize">{marketData.reportType}</td>
                                        <td className="report-table__cell cell_width_date">{publishDateStr}</td>
                                        <td className="report-table__cell cell_width_desc">
                                            <Button variant="info" onClick={(e) => handleShow(e, index)} size="sm"> View </Button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table >
            <DescriptionModal
                show={show}
                market={modalData}
                handleClose={handleClose}
            />
        </ >
    );
}

export default MarketUpdateTable;