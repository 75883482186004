import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NcdexStockFilter from './NcdexStockFilter';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import TableSection from 'components/dataService/TableSection';
import '../data_service.css';
import MobileCard from '../Mobile_Card';

function EditNcdexSpot() {

    const [filter, setFilter] = useState({
        commodity: '', location: '', startDate: '', endDate: new Date(), pageNumber: 1
    });

    const [responseData, setReponseData] = useState({
        records: [], maxPageNumber: 1
    });
    const [isLoading, setLoading] = useState(true);

    const fetchData = async () => {
        const path = `/api/ncdexStock/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&location=${filter.location}`;
        const successFunc = (data) => {
            setReponseData(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };


    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

    if (isLoading === false){
        if (responseData.records.length >= 0){
            return (
                <Container fluid>
                    <h2>NCDEX Stock</h2>
                    <Row>
                        <Col>
                            <NcdexStockFilter
                                setFilter={setFilter}
                                maxPages= {responseData.maxPageNumber}
                                submit={submitFilter}
                                clear={refreshPage}
                            />
                        </Col>
                        <Col  className='desktopView' xs={9}>
                            <TableSection className='desktopView'
                                dataList={responseData.records}
                                headerList={['date', 'location', 'commodity', 'value', 'unit']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
    
                    <br />
                    <MobileCard classname='MobView' dataList={responseData.records} headerList={['commodity', 'location', 'value', 'date']} />
                </Container>
            );
        } else {
            return (<div>No Records Found</div>);
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default EditNcdexSpot;