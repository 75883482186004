import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Row, Container } from 'react-bootstrap';
import MenuListField from './components/MenuListField';
import menuList from './menuList';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import './sidebarDrawer.css';
import LogoutButton from '../header/LogoutButton';

export default function SidebarMenuDrawer() {
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 200 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {
                menuList.map((menu, index) =>
                    <Row key={index} className='drawer_menu_field'>
                        <MenuListField
                            label={menu.label}
                            icon={menu.icon}
                            subMenuList={menu.subList}
                        />
                    </Row>
                )
            }
        </Box>
    );

    return (
        <Container fluid>
            {['left'].map((anchor) => (
                <><React.Fragment key={anchor}>
                    <Button className='DesktopMenuBtn' variant='primary' onClick={toggleDrawer(anchor, true)}>Menu</Button>
                    <Button className='MobileMenuBtn'  onClick={toggleDrawer(anchor, true)}><span>&#9776;</span></Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                        <LogoutButton style={{width: '63vw!important'}}></LogoutButton>
                    </SwipeableDrawer>
                    
                </React.Fragment>
                </> 
            ))}
        </Container>
    );
}
