import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthManager from 'AuthManager';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto';


ReactDOM.render(
  <React.StrictMode>
    <AuthManager />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
