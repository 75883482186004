import React, { useState, useEffect } from 'react';
import { getRequest } from 'utils/RequestUtils';
import DependentSelector from 'components/commons/DependentSelector';
import ApiLoader from '../commons/ApiLoader';

function CommodityCountrySelector({ path, selectCommodity, selectCountry, vertical = false }) {
    const [annualMap, setAnnualMap] = useState({});
    const [isLoading, setLoading] = useState(true);

    const fetchNcdexSpotMap = () => {
        const successFunc = (data) => {
            setAnnualMap(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchMap = async () => {
            await fetchNcdexSpotMap();
            setLoading(false);
        };
        fetchMap();
    }, []);


    if (isLoading || Object.keys(annualMap).length === 0) {
        return (
            <ApiLoader />
        );
    } else {
        return (
            <DependentSelector
                label1={'Commodity'}
                label2={'Country'}
                selectionMap={annualMap}
                subField={'country'}
                selectValue1={selectCommodity}
                selectValue2={selectCountry}
                vertical={vertical}
            />
        );
    }
}

export default CommodityCountrySelector;