/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Container, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { getRequest } from 'utils/RequestUtils';
import DependentSelector from '../commons/DependentSelector';
import ApiLoader from '../commons/ApiLoader';

function CommodityReportTypeSelector({ selectCommodity, selectReportType, vertical = false }) {

    const [reportMap, setReportMap] = useState({});
    const [isLoading, setLoading] = useState(true);

    const fetchReportMap = () => {
        const path = '/api/clientUser/clientReportMap';
        const successFunc = (data) => {
            setReportMap(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };
    useEffect(() => {
        const fetchMap = async () => {
            await fetchReportMap();
            setLoading(false);
        };
        fetchMap();
    }, []);

    if (isLoading || Object.keys(reportMap).length === 0) {
        return (
            <ApiLoader />
        );
    } else {
        return (
            <DependentSelector
                label1={'Commodity'}
                label2={'Report Type'}
                selectionMap={reportMap}
                subField={'reports'}
                selectValue1={selectCommodity}
                selectValue2={selectReportType}
                vertical={vertical}
            />
        );
    }
}

export default CommodityReportTypeSelector;