import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthContext } from './router/AuthContext';
import NeutralRouter from './router/NeutralRouter';
import ProtectedRouter from './router/ProtectedRouter';
import AuthRouter from './router/AuthRouter';
import Dashboard from './dashboard/Dashboard';
import LoginPage from './auth/login/LoginPage';

function AuthManager() {

    const authContextValue = React.useMemo(
        () => ({
            login: async (user, cb) => {
                await localStorage.setItem('email', user.email);
                await localStorage.setItem('token', user.token);
                cb();
            },
            logout: async () => {
                await localStorage.removeItem('email');
                await localStorage.removeItem('token');
                window.location.href = '/login'
            }
        }), []
    )

    return (
        <AuthContext.Provider value={authContextValue}>
            <Router>
                <Switch>
                    <NeutralRouter exact path='/' />
                    <AuthRouter path='/login'>
                        <LoginPage />
                    </AuthRouter>
                    <ProtectedRouter path='/dashboard'>
                        <Dashboard />
                    </ProtectedRouter>
                </Switch>
            </Router>
        </AuthContext.Provider>
    )
}

export default AuthManager;