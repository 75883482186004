import React from 'react';
import './router.css';
import { Route, Redirect } from "react-router-dom";

function ProtectedRouter({ children, ...rest }) {
    const token = localStorage.getItem('token');

    // render has location component by default. if token is not set, we forward in to componet 2 details:
    // Detail 1. Target Path => Login
    // Detail 2. State Object containing current path which will be used by Login further
    return (
        <Route
            {...rest}
            render={({ location }) => token ? children :
                <Redirect to={{ pathname: '/login', state: { from: location } }} />}
            className='protectedRouter'
        />
    );
}
export default ProtectedRouter;