import React, { useState, useEffect } from 'react';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import SelectField from 'components/commons/SelectField';

function CurrencyTracker({ selectCurrencyPairs }) {
    const [currencyPairs, setcurrencyPairs] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const fetchForexMap = () => {
        const path = '/api/forexCurrency/forexMap';
        const successFunc = (data) => {
            const currencyList = data.currencyPairs;
            setcurrencyPairs(currencyList);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchMap = async () => {
            await fetchForexMap();
            setLoading(false);
        };
        fetchMap();
    }, []);

    const handleCurrency = (currency) => {
        selectCurrencyPairs(currency);
    };

    if (isLoading === false){
        if ( currencyPairs.length >= 0) {
            return (
                <SelectField
                    label='Select Currency Pair'
                    arr={currencyPairs}
                    setSelection={handleCurrency}
                    defaultValue={''}
                />
            );
        } else {
            return (<div>No Records Found</div>);
        }
    } else {
        return <ApiLoader />;
    }
}

export default CurrencyTracker;