import React, { useState, useEffect } from 'react';
import { Container, Spinner, Row, Col } from 'react-bootstrap';
import SpotPriceFilter from './SpotPriceFilter';
import { getRequest } from 'utils/RequestUtils';
import TableSection from 'components/dataService/TableSection';
import '../data_service.css';
import MobileCard from '../Mobile_Card';

function SpotPrices() {
    
    const [filter, setFilter] = useState({
        commodity: '', location: '', startDate: '', endDate: '', pageNumber: 1
    });
    const [isLoading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState({
        maxPageNumber: 1,
        records: []
    });

    const fetchData = async () => {
        const path = `/api/spotPrices/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&location=${filter.location}`;
        const successFunc = (data) => {
            setResponseData(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };
    const refreshPage = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

  
    
    if (isLoading === false){
        if(responseData.records.length >= 0){
            return (
                <Container fluid>
                    <h2> Spot Prices </h2>
                    <Row >
                        <Col>
                            <SpotPriceFilter
                                setFilter={setFilter}
                                maxPages={responseData.maxPageNumber}
                                submit={submitFilter}
                                clear={refreshPage}
                            />
                        </Col>
                        <Col className='desktopView' xs={9}>
                            <TableSection className='desktopView'
                                dataList={responseData.records}
                                headerList={['date', 'commodity', 'location', 'variety', 'price', 'unit']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
                    <MobileCard classname='MobView' dataList={responseData.records} headerList={['variety', 'location', 'price', 'date']} />
                </Container>
            );
        }else{
            return(<div>No Records Found</div>);
        }
    }else{
        return(
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
}
export default SpotPrices;