import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SelectField from './SelectField';

function DependentSelector({ label1, label2, selectionMap, subField, selectValue1, selectValue2, vertical = false }) {

    const primaryArr = Object.keys(selectionMap);
    const [selection2, setSelection2] = useState({ arr: [], reset: false });

    const handleSelection1 = (aValue) => {
        selectValue1(aValue);
        let secondArr = [];
        if (Object.keys(selectionMap).includes(aValue)) {
            secondArr = selectionMap[aValue][subField];
        }
        setSelection2({ arr: secondArr, reset: !selection2.reset });
    };

    const handleSelection2 = (bValue) => {
        selectValue2(bValue);
    };

    if (vertical) {
        return (
            <div>
                <Row>
                    <SelectField label={label1} arr={primaryArr} setSelection={handleSelection1} />
                </Row>
                <Row>
                    <SelectField label={label2} arr={selection2.arr} setSelection={handleSelection2} reset={selection2.reset} />
                </Row>
            </div>
        );
    } else {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <SelectField label={label1} arr={primaryArr} setSelection={handleSelection1} />
                    </Col>
                    <Col>
                        <SelectField label={label2} arr={selection2.arr} setSelection={handleSelection2} reset={selection2.reset} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default DependentSelector;