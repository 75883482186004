import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
// import LogoutButton from './LogoutButton';
import SidebarMenuDrawer from '../sidebar/SidebarMenuDrawer';
import './header.css';
// import { style } from '@mui/system';

function Header() {
    return (
        <Container fluid className='header_container'>
            <Navbar expand="sm" className='header_navbar'>
                <SidebarMenuDrawer />
                <Navbar.Brand className='navbar_brand'> 
                    <b>agAlabs Dashboard</b>
                </Navbar.Brand>
                {/* <Navbar.Collapse className='justify-content-end'>
                    {/* <LogoutButton /> 
                </Navbar.Collapse> */}
            </Navbar>
        </Container>
    );
}

export default Header;
