/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col  , Button} from 'react-bootstrap';
import MarketUpdateTable from '../common/MarketUpdateTable';
import ApiLoader from 'components/commons/ApiLoader';
import ArchiveNewsFilter from './ArchiveNewsFilter';
import { getRequest } from 'utils/RequestUtils';
import './Archive.css'
// import MobileArchivesTable from '';
// import MobileReportCard from 'dashboard/agri_reports/commons/MobileReportCard';
import ArchiveNewsMobilecard from './ArchiveNewsMobileCard';

function ArchiveNewsPage() {
    const [filter, setFilter] = useState({
        commodity: '',
        reportType: '',
        startDate: '',
        endDate: new Date(),
        pageNumber: 1,
    });
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const clear = () => {
        window.location.reload(true);
    };

    const submit = (filter) => {
        setFilter(filter);
    };
     function showDiv() {
        var btn = document.getElementById('MobileFilter');
        var Mobcard = document.getElementById('MobileReportCard');
        btn.style.width = '100vw';
        var filterBtn = document.getElementById('filterBtn');
        filterBtn.innerHTML = 'Close';
        if (btn.style.display === "none") {
            Mobcard.style.marginTop= '2rem';
            btn.style.marginBottom = '0rem!important';
            filterBtn.innerHTML = 'Close';
            btn.style.display = "block";
          } else {
            btn.style.display = "none";
            filterBtn.innerHTML = 'Filters';
            Mobcard.style.marginTop= '-9vh' ;
            btn.style.marginBottom = '0rem!important';
          }
     }

    const fetchData = async () => {
        const path = `/api/report/fetchMarketUpdate?commodity=${filter.commodity}&reportType=${filter.reportType}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (data) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchMarketData = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchMarketData();
    }, [filter]);

    if (isLoading === false) {
        if (apiResponse.records.length > 0) {
            return (
                <div><Container fluid>
                    <h2 className='NewsHead'>Market Update</h2>
                    <Button className='filterBtn' id='filterBtn' onClick={() => showDiv()} >Filters</Button>
                    <Row id='MobileFilter'>
                        <Col >
                        <ArchiveNewsFilter
                                submit={submit}
                                clear={clear}
                                maxPages={apiResponse.maxPageNumber}
                            />
                        </Col>
                        <Col xs={9}>
                        <MarketUpdateTable marketList={apiResponse.records} refreshPage={fetchData} />
                        </Col>
                    </Row>
                    <Row id='PcFilter'>
                        <Col>
                            <ArchiveNewsFilter
                                submit={submit}
                                clear={clear}
                                maxPages={apiResponse.maxPageNumber} />
                        </Col>
                        {/* <MobileArchivesTable  /> */}
                        <Col xs={9} className='PcMarketUpdate'>
                            <MarketUpdateTable marketList={apiResponse.records} refreshPage={fetchData} />
                        </Col>
                    </Row>
                </Container>
                <ArchiveNewsMobilecard marketList={apiResponse.records} refreshPage={fetchData}/>
                </div>
                
                
            );
        } else {
            return (
                <h3>NO RECORDS FOUND, CONTACT ADMIN</h3>
            )
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default ArchiveNewsPage;