import React, { useState } from 'react';
import { Table, Button} from 'react-bootstrap';
import './archiveReport.css';
import moment from 'moment';
import { openFile } from 'utils/awsFileUtils';
import DescriptionModal from './DescriptionModal';

function ReportsTable({ reportList }) {

    const defaultVal = {

    };
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(defaultVal);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (event, index) => {
        event.preventDefault();
        const reportData = reportList[index];
        setModalData(reportData);
        setShow(true);
    };

    return (
        <>
                <Table size="sm">
                    <thead>
                        <tr className="report-table__header">
                            <th className="report-table__cell cell_width_commodity">Commodity</th>
                            <th className="report-table__cell cell_width_type">Report Type</th>
                            <th className="report-table__cell cell_width_date">Publish Date</th>
                            <th className="report-table__cell cell_width_name">File</th>
                            <th className="report-table__cell cell_width_desc">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                
                        {
                           
                            reportList.map((reportData, index) => {
                                const publishDateStr = `${moment(reportData.publishDate).format('dddd')}, ${moment(reportData.publishDate).format('MMM DD, YYYY')}`;
                                return (
                                    <tr key={index}>
                                        <td className="report-table__cell cell_width_commodity text-capitalize">{reportData.commodity}</td>
                                        <td className="report-table__cell cell_width_type text-capitalize">{reportData.reportType}</td>
                                        {/* <td className="report-table__cell">{moment(reportData.publishDate).format('DD-MM-YYYY')}</td> */}
                                        <td className="report-table__cell cell_width_date">{publishDateStr}</td>
                                        <td className="report-table__cell cell_width_name" onClick={() => openFile(reportData._id)}>
                                            <span className="report_file_link">{reportData.originalFileName}</span>
                                            
                                        </td>
                                        <td className="report-table__cell cell_width_desc">
                                            <Button variant="info" onClick={(e) => handleShow(e, index)} size="sm"> View </Button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table >
            <DescriptionModal
                show={show}
                report={modalData}
                handleClose={handleClose}
            />
           
        </ >
    );
}

export default ReportsTable;