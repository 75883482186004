import React from 'react';
import DataPipelineTable from 'components/dataService/DataPipelinetable';

function TableSection({ dataList, headerList, refreshPage }){
    return (
        <>
            <DataPipelineTable
                dataList={dataList}
                headerList={headerList}
                refreshPage={refreshPage}
            />
        </>
    );

}

export default TableSection;