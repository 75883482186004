import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NcdexSpotFilter from './NcdexSpotFilter';
import ApiLoader from 'components/commons/ApiLoader';
import { getRequest } from 'utils/RequestUtils';
import TableSection from 'components/dataService/TableSection';
import '../data_service.css';
import MobileCard from '../Mobile_Card';

function EditNcdexSpot() {

    const [filter, setFilter] = useState({
        commodity: '', location: '', startDate: '', endDate: '', pageNumber: 1, exchange: 'NCDEX', variety: ''
    });
    const [isLoading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState({
        records: [], maxPageNumber: 1
    });


    const fetchData = async () => {
        const path = `/api/ncdexSpot/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&location=${filter.location}&exchange=${filter.exchange}&variety=${filter.variety}`;
        const successFunc = (data) => {
            setResponseData(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

    if (isLoading === false){
        
        if (responseData.records.length >= 0){
            return (
                <Container fluid>
                    <h2>NCDEX Spot</h2>
                    <Row>
                        <Col>
                            <NcdexSpotFilter
                                setFilter={setFilter}
                                maxPages={responseData.maxPageNumber}
                                submit={submitFilter}
                                clear={refreshPage}
                            />
                        </Col>
                        <Col className='desktopView'xs={9}>
                            <TableSection className='desktopView'
                                dataList={responseData.records}
                                headerList={['date', 'location', 'commodity', 'price', 'unit']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
                    <MobileCard classname='MobView' dataList={responseData.records} headerList={['commodity', 'location', 'price', 'date']} />
                </Container>
            );
        } else {
            return (<div>No Records Found</div>);
        }
    } else {
        return <ApiLoader />;
    }
}

export default EditNcdexSpot;