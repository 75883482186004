import React from 'react';
import { Redirect } from 'react-router-dom';

function NeutralRouter() {
    const token = localStorage.getItem('token');

    if (token) {
        return <Redirect to='/dashboard' />;
    }
    else {
        return <Redirect to='/login' />;
    }
}

export default NeutralRouter;