import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LoginForm from './LoginForm';
import AgaBgImg from 'resources/images/extras/agalabs-bg.jpg';

const styles = {
    body: {
        height: '100vh',
        backgroundImage: `url(${AgaBgImg})`,
        paddingTop: '5vh',
    }
}

function LoginPage() {
    return (
        <Container fluid style={styles.body}>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <Card>
                        <Card.Header>
                            <h3>agAlabs Login </h3>
                        </Card.Header>
                        <Card body>
                            <LoginForm />
                        </Card>
                        <Card.Footer>
                            <div>
                                <span>
                                    <a href="mailto:ram@agalabs.com?subject=Please Change my Password - <Enter you Email ID>">
                                        Forgot password
                                    </a>
                                </span>
                                <span> | </span>
                                <a href="mailto:ram@agalabs.com">
                                    <span> Contact Admin </span>
                                </a>
                                
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col sm={4}></Col>
            </Row>
        </Container>
    )
}

export default LoginPage;