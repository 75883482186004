import React, { useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import AgmCommodityDropdown from './AgmCommodityDropdown';
import DateRangeFilter from 'components/dataService/DateRangeFilter';
import PageManager from 'components/commons/PageManager';
import SubmitClearBtn from 'components/commons/SubmitClearBtn';

function AgmFilter({ setFilter, submit, maxPages, clear }) {

    const [commodity, setCommodity] = useState('');
    const [state, setState] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);

    const submitFilter = () => {
        const filter = {
            commodity, state, startDate, endDate, pageNumber
        };
        setFilter(filter);
        submit();
    };

    return (
        <Container>
            <Form>
                <AgmCommodityDropdown setCommodity={setCommodity} setState={setState} />
                <DateRangeFilter setStartDate={setStartDate} setEndDate={setEndDate} />
                <Row>
                    <PageManager managePage={setPageNumber} maxPages={maxPages} />
                </Row>
                <br/>
                <SubmitClearBtn submit={submitFilter} clear={clear} />
            </Form>
        </Container>
    );

        
}

export default AgmFilter;