import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AgmFilter from './AgmFilter';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import TableSection from 'components/dataService/TableSection';

function EditAgmArrivals() {

    const [filter, setFilter] = useState({
        commodity: '',
        state: '',
        startDate: '',
        endDate: new Date(),
        pageNumber: 1,
    });
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const fetchAgmData = async () => {
        const path = `/api/agmarknet/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&state=${filter.state}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submit = () => {
        fetchAgmData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchAgmData();
            setLoading(false);
        };
        fetchData();
    }, [filter]);

    if (isLoading === false){
        if (apiResponse.records.length >= 0){
            return (
                <Container fluid>
                    <h2> Agmarknet Arrivals </h2>
                    <Row>
                        <Col>
                            <AgmFilter
                                setFilter={setFilter}
                                submit={submit}
                                maxPages={apiResponse.maxPageNumber}
                                clear={refreshPage}
                            />
                        </Col>
                        <Col xs={9}>
                            <TableSection 
                               dataList={apiResponse.records}
                               headerList={['date', 'commodity', 'state', 'value']}
                               refreshPage={fetchAgmData}
                            />
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <div>No Records Found</div>
            );
        }
    } else {
        return (
            <ApiLoader />
        );
    }

}

export default EditAgmArrivals;