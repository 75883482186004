import React, { useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import DateRangeFilter from 'components/dataService/DateRangeFilter';
import CurrencyTracker from './CurrencyTracker';
import SubmitClearBtn from 'components/commons/SubmitClearBtn';
import PageManager from 'components/commons/PageManager';

function ForexFilter({ setFilter, maxPages, submit, clear }) {

    const [currencyPair, setCurrencyPair] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);


    const pressSubmit = (e) => {
        const filterObj = {
            currencyPair, startDate, endDate, pageNumber
        };
        setFilter(filterObj);
        submit();
    };

    return (
        <Container>
            <Form>
                <Row>
                    <CurrencyTracker selectCurrencyPairs={setCurrencyPair} />
                </Row>
                <DateRangeFilter setStartDate={setStartDate} setEndDate={setEndDate} />
                <Row style={{marginTop:'20px',marginBottom:'20px'}}>
                    <PageManager managePage={setPageNumber} maxPages={maxPages} />
                </Row>
                <SubmitClearBtn submit={pressSubmit} clear={clear} />
            </Form>
        </Container>
    );

}

export default ForexFilter;