import './archiveReport.css';
import MobileLatestReportCard from './MobileLastestReportCard';

function ReportsTable({ reportList }) {
   return (
        <>  {
                            reportList.map((reportData, index) => {
                                return (<MobileLatestReportCard reportData={reportData} key={index} />)

                            })
                        }
        </ >
    );
}

export default ReportsTable;