import React from 'react';
import { Card, Table } from 'react-bootstrap';
import moment from 'moment';

function SubscriptionSection ({ clientData }){

    return (
        <Card className='card2'>
            <Card.Header>
                Subscription Details
            </Card.Header>
            <Card.Body className='carbody2'>
                <Table size="sm">
                    <thead>
                        <th className='title'>Commodity</th>
                        <th className='title'>Status</th>
                        <th className='title'>Expiry Date</th>
                    </thead>
                    <tbody>
                        {
                            clientData.subscriptions.map(sub => {
                                const expiryDateStr =  `${moment(sub.expiryDate).format('dddd')}, ${moment(sub.expiryDate).format('MMM DD, YYYY')}`;
                                return (
                                    <tr>
                                        <td>{sub.commodity}</td>
                                        <td>{sub.status}</td>
                                        <td>{expiryDateStr}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )

}

export default SubscriptionSection;