/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ReportsTable from '../commons/ReportsTable';
import ApiLoader from 'components/commons/ApiLoader';
import ArchiveFilter from './ArchiveFilter';
import { getRequest } from 'utils/RequestUtils';
import MobileArchivesTable from '../commons/MobileArchivesTable'

function ArchivePage() {
    const [filter, setFilter] = useState({
        commodity: '',
        reportType: '',
        startDate: '',
        endDate: new Date(),
        pageNumber: 1,
    });
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const clear = () => {
        window.location.reload(true);
    };

    const submit = (filter) => {
        setFilter(filter);
    };

    const fetchData = async () => {
        const path = `/api/reports/fetchList?commodity=${filter.commodity}&reportType=${filter.reportType}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (data) => { };
        getRequest(path, successFunc, errorFunc);
    };

    useEffect(() => {
        const fetchReportData = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchReportData();
    }, [filter]);
    function showDiv() {
        var btn = document.getElementById('MobileFilter');
        var Mobcard = document.getElementById('MobileReportCard');
        btn.style.width = '100vw';
        var filterBtn = document.getElementById('filterBtn');
        // filterBtn.innerHTML = 'Close';
        if (btn.style.display === "none") {
            Mobcard.style.marginTop= '2rem';
            btn.style.marginBottom = '0rem!important';
            filterBtn.innerHTML = 'Close';
            btn.style.display = "block";
          } else {
            btn.style.display = "none";
            filterBtn.innerHTML = 'Filters';
            Mobcard.style.marginTop= '-9vh' ;
            btn.style.marginBottom = '0rem!important';
          }
     }
    
    if (isLoading === false) {
        if (apiResponse.records.length > 0) {
            return (
                <Container fluid>
                    <h2 id='ArchivesHeading' classNAme='HeadingReport'> Archives  </h2>
                    <Button className='filterBtn' id='filterBtn' onClick={() => showDiv()} >Filters</Button>
                    <Row id='MobileFilter'>
                        <Col >
                            <ArchiveFilter
                                submit={submit}
                                clear={clear}
                                maxPages={apiResponse.maxPageNumber}
                            />
                        </Col>
                        <Col xs={9}>
                            <ReportsTable reportList={apiResponse.records} refreshPage={fetchData} />
                        </Col>
                    </Row>
                    <Row id='PcFilter'>
                        <Col >
                            <ArchiveFilter
                                submit={submit}
                                clear={clear}
                                maxPages={apiResponse.maxPageNumber}
                            />
                        </Col>
                        <Col xs={9}>
                            <ReportsTable reportList={apiResponse.records} refreshPage={fetchData} />
                        </Col>
                    </Row>
                <MobileArchivesTable reportList={apiResponse.records} refreshPage={fetchData} />

</Container>
            );
        } else {
            return (
                <h3>NO RECORDS FOUND, CONTACT ADMIN</h3>
            )
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default ArchivePage;