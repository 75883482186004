import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import TableSection from 'components/dataService/TableSection';
import CommLocationDateRangeFilter from 'components/dataService/CommLocationDateRangeFilter';
// import Mobile_Card from '../Mobile_Card';
// import './data_service.css';
import '../data_service.css';
import MobileCard from '../Mobile_Card';

function FcaRetail() {

    const [filter, setFilter] = useState({
        commodity: '',
        location: '',
        startDate: '',
        endDate: '',
        pageNumber: 1,
    });
    
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const fetchData = async () => {
        const path = `/api/fcaRetail/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&location=${filter.location}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

    if(isLoading===false){
        if (apiResponse.records.length >= 0){
            return (
                <Container fluid>
                    <h2>Fca Retail</h2>
                    <Row id ="remove">
                        <Col>
                            <CommLocationDateRangeFilter
                                setFilter={setFilter}
                                maxPages={apiResponse.maxPageNumber}
                                submit={submitFilter}
                                clear={refreshPage}
                                requestPath={'/api/fcaRetail/fcaRetailMap'}
                            />
                        </Col>
                        <Col className='desktopView' xs={9}>
                            <TableSection className='desktopView'
                                dataList={apiResponse.records}
                                headerList={['date', 'location', 'commodity', 'price', 'unit']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
                    <MobileCard classname='MobView'dataList={apiResponse.records} headerList={['commodity', 'location', 'price', 'date']} />
                    
                </Container>
            );
        } else {
            return (
                <div>
                    No Records Found
                </div>
            );
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default FcaRetail;