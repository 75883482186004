import React,  { useState }  from 'react';
import { Card, Row, Button } from 'react-bootstrap';
import moment from 'moment';
import DescriptionModal from '../common/DescriptionModal';


function MobileMarketUpdatTable({ marketList }){
    const defaultVal = {

    };
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(defaultVal);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (event, index) => {
        event.preventDefault();
        const marketData = marketList[index];
        setModalData(marketData);
        setShow(true);
    };
    return (
        <><div>
             {marketList.map((marketData, index) => {
                    const publishDateStr = `${moment(marketData.publishDate).format('dddd')}, ${moment(marketData.publishDate).format('MMM DD, YYYY')}`;
                    return (<Card className='MobileReportCard' id='MobileReportCard' style={{ marginBottom:'5rem' , border: 'solid black 0.1rem' }}>
            <Card.Body style={{ padingLeft: '1rem', width: '100%' }}>
               
                        <Row key={index}>
                            <div className='mobRepCard_Col_1'>
                                <Row className='ReportType' style={{fontSize: '0.7rem'}} >              
                                        {marketData.heading} 
                                        {/* jfjlkjf fh sjsjsf kfjlkjf fsf ht lwjwej lk kjthe qiukc brwonf jflksjflskdfjslksj jf lkfjsd f sdkjfs */} 
                                    {/* jfjlkjf fh sjsjsf kfjlkjf fsf ht lwjwej lk kjthe qiukc brwonf jflksjflskdfjslksj jf lkfjsd f sdkjfs kljf slkfj sfkj fsjfjlskfj fjsflksjfjf skfjf jf fksdf sfj fkjf sfjk fsdklfj fkjs flksfjslkfjf lskfj lkf fklfjsfkljflkfjlfkj fskf f jklffsdjf fkjsdflkjdsf f sfjlfjs lkjf sdlkfj fsd flks j sdkljf kflj fkjff sdkljfs fkljf sdlkfjfj lk kdfj kfj kfj sf jfksdj ffj fkjds f */}
                                </Row>
                                <Row style={{fontSize: '0.6rem' , }}>{marketData.reportType} | {publishDateStr}</Row>
                            </div>
                            <div className='mobRepCard_Col_2'>
                               
                                <Button  size="sm"
                                    variant='success'
                                    onClick={(e) => handleShow(e, index)}
                                >
                                    <center>&darr;</center>
                                </Button>
                            </div>
                        </Row>
                    
            </Card.Body>
        </Card>
        );
    })} </div><DescriptionModal
                show={show}
                market={modalData}
                handleClose={handleClose} /></>                      
    );
}
export default MobileMarketUpdatTable;
