import React, { useState } from 'react';
// import MenuIconFactory from './MenuIconFactory';
import SubMenuField from './subMenu/SubMenuField';
import classNames from 'classnames';
import './menuList.css';

function getSubMenu(subMenuList) {
    return (
        <div className={'sidebar_submenu'}>
            {subMenuList.map((subMenu, index) => <SubMenuField link={subMenu.link} label={subMenu.label} key={index} />)}
        </div>
    );
}

function MenuListField({ label, icon, subMenuList }) {
    const [isMouseOver, setMouseOver] = useState(false);
    const [showTree, setShowTree] = useState(false);

    return (
        <div className={'sidebar_menuField_primary'}>
            <div
                className={classNames({
                    'sidebar_navItem': true,
                    'sidebar_navItemOnHover': isMouseOver
                })}
                onMouseEnter={() => setMouseOver(!isMouseOver)}
                onMouseLeave={() => setMouseOver(!isMouseOver)}
                onClick={() => setShowTree(!showTree)}
            >
                <span>
                    <label className={'sidebar_navLabel'}>{label}</label>
                </span>
            </div>
            {showTree ? getSubMenu(subMenuList) : null}
        </div >
    );
}

export default MenuListField;