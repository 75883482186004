async function Logout() {
    await localStorage.removeItem('email');
    await localStorage.removeItem('token');
    window.location.href = '/login';
};

export function postRequest(path, requestBody, successFunc, errorFunc) {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'X-ACCESS-TOKEN': token
        },
        body: JSON.stringify(requestBody)
    }).then(r => r.json()).then(res => {
        if (res.success === true) {
            successFunc(res.data);
        } else {
            alert(`Error : ${res.data.message}`);
            if (res.data.statusCode === 407) {
                Logout();
            } else {
                errorFunc(res);
            }
        }
    });
}

export function uploadFile(path, formData, successFunc, errorFunc) {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
        method: 'POST',
        headers: {
            'X-ACCESS-TOKEN': token
        },
        body: formData
    }).then(r => r.json()).then(res => {
        if (res.success === true) {
            successFunc(res.data);
        } else {
            alert(`Error : ${res.data.message}`);
            if (res.data.statusCode === 407) {
                Logout();
            } else {
                errorFunc(res);
            }
        }
    });
}


export function getRequest(path, successFunc, errorFunc) {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
        headers: {
            'Content-type': 'application/json',
            'X-ACCESS-TOKEN': token
        }
    }).then(r => {
        return r.json();
    })
        .then(res => {
            if (res.success === true) {
                successFunc(res.data);
            } else {
                alert(`Error : ${res.data.message}`);
                if (res.data.statusCode === 407) {
                    Logout();
                } else {
                    errorFunc(res);
                }
            }
        });
}