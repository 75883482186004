import React from 'react';
import { Route, Redirect } from "react-router-dom";

function AuthRouter({ children, ...rest }) {
    const token = localStorage.getItem('token');
    return (
        <Route {...rest}
            render={({ location }) => token ?
                <Redirect to={{ pathname: '/dashboard' }} /> : children
            }
        />
    );
}

export default AuthRouter;