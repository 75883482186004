import React, { useState, useContext } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import { AuthContext } from 'router/AuthContext';
import { useLocation, useHistory } from 'react-router-dom';
import { postRequest } from 'utils/RequestUtils';

function LoginForm() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);
    const location = useLocation();
    const history = useHistory();

    let { from } = location.state || { from: { pathname: '/dashboard' } };

    const handleEmail = (value) => {
        setEmail(value);
    }

    const handlePassword = (value) => {
        setPassword(value);
    }

    const submitLogin = (event) => {
        event.preventDefault();
        const body = {
            email: email,
            password: password,
        };
        console.log(`AUth Body : ${JSON.stringify(body)}`)
        const path = '/auth/authenticate';
        const successFunc = (data) => {
            const user = { email: email, token: data.token };
            const cb = () => { history.replace(from); };
            login(user, cb);
        };
        const errorFunc = (res) => {
            // alert('Incorrect Credentials');
        };
        postRequest(path, body, successFunc, errorFunc);
    }

    return (
        <Container fluid>
            <Card>
                <Card body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                onChange={e => handleEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={e => handlePassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={e => submitLogin(e)} >
                            Submit
                        </Button>
                    </Form>
                </Card>
            </Card>
        </Container>
    )
}

export default LoginForm;