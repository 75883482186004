import { EditorState, convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import ReactHtmlParser from 'react-html-parser'

export function getHtml(description){
    let currentContent = convertFromRaw(description);
    let editorState = EditorState.createWithContent(currentContent);
    let html1 = convertToHTML(editorState.getCurrentContent());
    const component = ReactHtmlParser(html1);
    return component;
}