import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function SelectField({ label, arr, setSelection, defaultValue = '', reset = false }) {

    const defaultOption = { value: '', label: '--SELECT--' };

    useEffect(() => {
        setSelection(defaultValue);
    }, [reset]);

    const handleSelection = (value) => {
        setSelection(value);
    };

    const aOptions = [];
    aOptions.push(defaultOption);
    for (var i = 0; arr[i]; i++) {
        var obj = {};
        obj['value'] = arr[i];
        obj['label'] = arr[i];
        aOptions.push(obj);
    }

    const getInitialValue = () => {
        const val = aOptions.filter((t) => t.value === defaultValue);
        return val;
    };

    return (
        <Form.Group style={{ width: '100%' }}>
            {
                (label !== '') ? (<Form.Label>{label}</Form.Label>) : <></>
            }
            <Select
                defaultValue={getInitialValue()}
                options={aOptions}
                onChange={(options) => handleSelection(options['value'])}
            />
        </Form.Group>
    );
}

export default SelectField;