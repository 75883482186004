import React, { useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import CommodityCountrySelector from 'components/selectors/CommodityCountrySelector';
import PageManager from 'components/commons/PageManager';
import SubmitClearBtn from 'components/commons/SubmitClearBtn';

function AnnualExImFilter({ setFilter, submit, clear, maxPages }) {

    const [commodity, setCommodity] = useState('');
    const [country, setCountry] = useState('');
    const [year, setYear] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [hsCode, setHsCode] = useState('');
    

    const submitFilter = () => {
        const filter = {
            commodity, country, year, pageNumber, hsCode
        };
        setFilter(filter);
        submit();
    };

    return (
        <Container>
            <Form>
                <CommodityCountrySelector path={'/api/annualExIm/getAnnualExImMap'} selectCommodity={setCommodity} selectCountry={setCountry} vertical={true} />
                <Row>
                    <Form.Group controlId="FormVariety">
                        <Form.Label>HS Code</Form.Label>
                        <Form.Control type='name' placeholder='Enter hsCode' onChange={(e) => setHsCode(e.target.value)} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="FormVariety">
                        <Form.Label>Year</Form.Label>
                        <Form.Control type='name' placeholder='Enter year' onChange={(e) => setYear(e.target.value)} />
                    </Form.Group>
                </Row>
                <Row style={{marginTop:'20px',marginBottom:'20px'}}>
                    <PageManager managePage={setPageNumber} maxPages={maxPages} />
                </Row>
                <SubmitClearBtn submit={submitFilter} clear={clear} />
            </Form>
        </Container>
    );
}

export default AnnualExImFilter;