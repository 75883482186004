import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReportsTable from '../commons/ReportsTable';
import ApiLoader from 'components/commons/ApiLoader';
import { getRequest } from 'utils/RequestUtils';
import './latestReportPage.css';
import MobileReportsTable from '../commons/MobileReportsTable';

function LatestReportsPage() {
    const [isLoading, setLoading] = useState(true);
    const [apiResponse, setApiResponse] = useState({});

    const fetchRecords = () => {
        const path = '/api/reports/latest';
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    }

    useEffect(() => {
        const fetch = async () => {
            await fetchRecords();
            setLoading(false);
        };
        fetch();
    }, []);

    if (isLoading === false) {
        if (Object.keys(apiResponse).length > 0) {
            return (
                <Container fluid>
                    <Row className="LatestReport_Heading">
                        <h3 className='HeadingReport'>Latest Reports</h3>
                    </Row>
                    <Row  className='latestReportSection'>
                    {
                            Object.keys(apiResponse).map((reportType, index) => {
                                return (
                                    <Row key={index} className='reportCollectionRow'>
                                        <Col md={12}>
                                            <b>{reportType}</b>
                                            <MobileReportsTable reportList={apiResponse[reportType]} style={{marginTop: '5rem!important'}}/>
                                            <ReportsTable  className='DesktopTable' reportList={apiResponse[reportType]}  />
                                        </Col>
                                    </Row>
                                    
                                )
                            })
                    }
                    </Row>
                </Container>
                
            )
        } else {
            return (
                <h3>NO RECORDS FOUND</h3>
            )
        }
    } else {
        return <ApiLoader />
    }
}


export default LatestReportsPage;