import React, { useState } from 'react';
import * as Icon from 'react-feather';

const styles = {
    container: {
        textAlign: 'left',
        padding: '3px',
    },
    aLink: {
        textDecoration: 'none'
    },
    spanContainer: {
        fontSize: '14px',
        color: 'RGB(163, 209, 194)',
        marginLeft: '20px',
    },
    spanContainerOnHover: {
        fontSize: '14px',
        color: 'RGB(255, 255, 255)',
        marginLeft: '20px',
        fontWeight: 'bold',
    },
    labelStyle: {
        marginLeft: '10px',
    }
};

function SubMenuField(props) {
    const [isMouseOver, setMouseOver] = useState(false);

    return (
        <div
            style={styles.container}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            <a href={props.link} style={styles.aLink}>
                <span
                    style={isMouseOver ? styles.spanContainerOnHover : styles.spanContainer}
                >
                    <Icon.Circle size={12} />
                    <span style={styles.labelStyle}>{props.label}</span>
                </span>
            </a>
        </div>
    );
}

export default SubMenuField;