import React from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import moment from 'moment';
import { getHtml } from 'utils/getHtmlFromDraft';

function DescriptionModal({ show, market, handleClose }) {

    if (show===false || market === undefined || market.newsContent === undefined) {
        return (
            <></>
        )
    } else {
        const publishDateStr = `${moment(market.publishDate).format('dddd')}, ${moment(market.publishDate).format('MMM DD, YYYY')}`;
        return (
            <Modal show={show} onHide={handleClose} size='lg' >
                <Modal.Body>
                    <Card>
                        <Card.Header>
                            {market.heading} - {publishDateStr}
                        </Card.Header>
                        <Card.Body>
                            {getHtml(market.newsContent)}
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DescriptionModal;