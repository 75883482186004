import React, { useState, useEffect } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getSearchParams } from 'utils/PatternUtils';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import { openFile } from 'utils/awsFileUtils';
import moment from 'moment';
import './singleReportPage.css';
import { getHtml } from 'utils/getHtmlFromDraft';

function SingleReportPage() {
    const searchParams = useLocation();
    const [apiResponse, setApiResponse] = useState({});
    const [isLoading, setLoading] = useState(true);


    const fetchReport = () => {
        const paramDict = getSearchParams(searchParams);
        if (Object.keys(paramDict).length > 0) {
            const path = `/api/reports/fetchReport?id=${paramDict['key']}`;
            const successFunc = (data) => {
                setApiResponse(data);
            };
            const errorFunc = (res) => { };
            getRequest(path, successFunc, errorFunc);
        } else {
        }
    }

    const getFile = (e, id) => {
        e.preventDefault();
        openFile(id);
    }

    useEffect(() => {
        const fetch = async () => {
            await fetchReport();
            setLoading(false);
        }
        fetch();
    }, []);

    if (isLoading === false) {
        if (Object.keys(apiResponse).length !== 0) {
            // const publishDate = moment(apiResponse.publishDate).format('YYYY-MM-DD');
            const publishDateStr = `${moment(apiResponse.publishDate).format('dddd')}, ${moment(apiResponse.publishDate).format('MMM DD, YYYY')}`;
            return (
                <Container fluid >
                    <Card>
                        <Card.Header>{apiResponse.reportType} - {publishDateStr}</Card.Header>
                        <Card.Body>
                            <h2>Quick Take Away for report : </h2>
                            <div>
                                {getHtml(apiResponse.description)}
                            </div>
                            <div>
                                <Button onClick={e => getFile(e, apiResponse._id)} variant='primary'>View Report</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>

            )
        } else {
            return (
                <div>
                    <h3>RECORD NOT FOUND</h3>
                </div>
            )
        }
    } else {
        return (
            <ApiLoader />
        )
    }
}

export default SingleReportPage;