import React from 'react';
import {Row, Col, Button } from 'react-bootstrap';

function SubmitClearBtn({ submit, clear }){

    const pressSubmit = (e) => {
        e.preventDefault();
        submit();
    };

    const pressClear = (e) => {
        e.preventDefault();
        clear();
    };

    return (
        <Row>
            <Col>
                <Button variant='primary' onClick={e => pressSubmit(e)} >SUBMIT</Button>
            </Col>
            <Col>
                <Button variant='primary' onClick={(e) => pressClear(e)} > Clear </Button>
            </Col>
        </Row>
    );
}

export default SubmitClearBtn;