
const menuList = [
    {
        icon: 'users',
        label: 'User',
        subList: [
            { link: '/dashboard/user/profile', label: 'User Profile' },
            // {link: '/dashboard/LogoutButton' , label: 'Logout'  },
        ]
    },
    {
        icon: 'layers',
        label: 'Archive',
        subList: [
            { link: '/dashboard/reports/latest', label: 'Latest Report' },
            { link: '/dashboard/reports/archive', label: 'Archives Report' },
        ]
    },
    {
        icon: 'layers',
        label: 'Market Update',
        subList: [
            { link: '/dashboard/marketUpdate/archive', label: 'Market Update' },
        ]
    },
    // {
    //     icon: 'layers',
    //     label: 'Price Info',
    //     subList: [
    //         { link: '/dashboard/priceInfo/spotPrice', label: 'Spot Prices' },
    //         { link: '/dashboard/priceInfo/fcaRetail', label: 'Fca Retail'},
    //         { link: '/dashboard/priceInfo/fcaWholesale', label: 'Fca Wholesale'},
    //         { link: '/dashboard/priceInfo/ncdexSpot', label: 'Ncdex Spot'},
    //         { link: '/dashboard/priceInfo/ncdexStock', label: 'Ncdex Stock'},
    //         { link: '/dashboard/priceInfo/forex', label: 'Forex'},
    //     ]
    // },
    // {
    //     icon: 'layers',
    //     label: 'Trade Details',
    //     subList: [
    //         { link: '/dashboard/tradeDetails/annualExIm', label: 'Annual Export/Import' },
    //         { link: '/dashboard/tradeDetails/monthlyExIm', label: 'Monthly Export/Import' },
    //         { link: '/dashboard/tradeDetails/agmarknetArrival', label: 'Agmarknet Arrival'},

    //     ]
    // },
];

export default menuList;