import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CommodityReportTypeSelector from 'components/selectors/CommodityReportTypeSelector';
import DateRangeFilter from 'components/commons/DateRangeFilter';
import PageManager from 'components/commons/PageManager';

function ArchiveFilter({ submit, clear, maxPages }) {

    const [commodity, setCommodity] = useState('');
    const [reportType, setReportType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);

    const pressSubmit = (e) => {
        e.preventDefault();
        const filter = {
            commodity, reportType, startDate, endDate, pageNumber
        };
        submit(filter);
    };

    const pressClear = (e) => {
        e.preventDefault();
        clear();
    };

    return (
        <Container fluid>
            <CommodityReportTypeSelector selectCommodity={setCommodity} selectReportType={setReportType} vertical={true} />
            <Form>
                <br/>
                <DateRangeFilter setStartDate={setStartDate} setEndDate={setEndDate} startDateLabel='Start Publish Date' endDateLabel='End Publish Date' />
                <br />
                <Row>
                    <PageManager managePage={setPageNumber} maxPages={maxPages}  />
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button className='SubmitBtn' variant='primary' onClick={(e) => pressSubmit(e)} > Submit </Button>
                    </Col>

                    <Col>
                        <Button className='ClearBtn' variant='primary' onClick={(e) => pressClear(e)} > Clear </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default ArchiveFilter;