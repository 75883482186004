import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getRequest } from 'utils/RequestUtils';
import ApiLoader from 'components/commons/ApiLoader';
import CommLocationDateRangeFilter from 'components/dataService/CommLocationDateRangeFilter';
import TableSection from 'components/dataService/TableSection';
import MobileCard from '../Mobile_Card';
import '../data_service.css';

function FcaWholesale() {

    const [filter, setFilter] = useState({
        commodity: '',
        location: '',
        startDate: '',
        endDate: new Date(),
        pageNumber: 1,
    });

    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);

    const fetchData = async () => {
        const path = `/api/fcaWholesale/fetch?commodity=${filter.commodity}&startDate=${filter.startDate}&endDate=${filter.endDate}&pageNumber=${filter.pageNumber}&location=${filter.location}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchData();
    };

    const refreshPage = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        const fetchDataFirst = async () => {
            await fetchData();
            setLoading(false);
        };
        fetchDataFirst();
    }, [filter]);

    if (isLoading === false) {
        if (apiResponse.records.length >= 0) {
            return (
                <Container fluid>
                    <h2>Fca Wholesale</h2>
                    <Row>
                        <Col>
                            <CommLocationDateRangeFilter
                                setFilter={setFilter}
                                maxPages={apiResponse.maxPageNumber}
                                submit={submitFilter}
                                clear={refreshPage}
                                requestPath={'/api//fcaWholesale/fcaWholesaleMap'}
                            />
                        </Col>
                        <Col className='desktopView' xs={9}>
                            <TableSection className='desktopView'
                                dataList={apiResponse.records}
                                headerList={['date', 'location', 'commodity', 'price', 'unit']}
                                refreshPage={fetchData}
                            />
                        </Col>
                    </Row>
                    <MobileCard classname='MobView' dataList={apiResponse.records} headerList={['commodity', 'location', 'price', 'date']} />
                </Container>
            );
        } else {
            return (
                <div>NO Records Found</div>
            );
        }
    } else {
        return (
            <ApiLoader />
        );
    }
}

export default FcaWholesale;