import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getRequest } from 'utils/RequestUtils';
import ForexFilter from './ForexFilter';
import ApiLoader from 'components/commons/ApiLoader';
import TableSection from 'components/dataService/TableSection';

function Forex() {

    const [filter, setFilter] = useState({
        currencyPair: '',
        startDate: '',
        endDate: new Date(),
        pageNumber: 1,
    });
    const [apiResponse, setApiResponse] = useState({ records: [], maxPageNumber: 1 });
    const [isLoading, setLoading] = useState(true);
    

    const fetchForexData = () => {
        const path = `/api/forexCurrency/fetch?pageNumber=${filter.pageNumber}&currencyPairs=${filter.currencyPair}&startDate=${filter.startDate}&endDate=${filter.endDate}`;
        const successFunc = (data) => {
            setApiResponse(data);
        };
        const errorFunc = (res) => { };
        getRequest(path, successFunc, errorFunc);
    };

    const submitFilter = () => {
        fetchForexData();
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchForexData();
            setLoading(false);
        };
        fetchData();
    }, [filter]);

    const refreshPage = () => {
        window.location.reload(true);
    };


    if (isLoading === false){
        if (apiResponse.records.length >= 0){
            return (
                <Container fluid>
                    <h2>Forex</h2>
                    <Row>
                        <Col>
                            <ForexFilter
                                setFilter={setFilter}
                                submit={submitFilter}
                                clear = {refreshPage}
                                maxPages={apiResponse.maxPageNumber}
                            />
                        </Col>
                        <Col xs={9}>
                            <TableSection
                                dataList={apiResponse.records}
                                headerList={['date', 'currencyPairs', 'value', 'status']}
                                refreshPage={fetchForexData}
                            />
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <div>No Records Found </div>
            );
        }
    } else {
        return <ApiLoader />;
    }

}

export default Forex;