import React from 'react';
import './archiveReport.css';
import MobileReportCard from './MobileReportCard';

function ReportsTable({ reportList }) {
    return (
        <>
            {
                reportList.map((reportData, index) => {
                    return (<MobileReportCard reportData={reportData} key={index} />)
                })
            }
        </ >
    );
}

export default ReportsTable;